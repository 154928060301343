/* eslint-disable max-len */

export const csTranslations = {
  'header.top.menu.about': 'O Škoda Plus',
  'header.top.menu.buying': 'Kupuji vůz',
  'header.top.menu.selling': 'Prodávám vůz',
  'header.top.menu.branches': 'Naše pobočky',
  'header.top.menu.financing': 'Financování a služby',

  'header.main.menu.title.skodaplus': 'Škoda Plus',
  'header.main.menu.title.offer': 'Nabídka vozů',
  'header.main.menu.title.products': 'Financování a služby',

  'header.main.menu.item.homepage': 'Úvodní stránka',
  'header.main.menu.item.about': 'O Škoda Plus',
  'header.main.menu.item.contact': 'Kontakt',
  'header.main.menu.item.branches': 'Naše pobočky',
  'header.main.menu.item.career': 'Kariéra',
  'header.main.menu.item.buying': 'Kupuji vůz',
  'header.main.menu.item.selling': 'Prodávám vůz',
  'header.main.menu.item.yearly': 'Roční vozy',
  'header.main.menu.item.tried': 'Vyzkoušené vozy',
  'header.main.menu.item.used': 'Ojeté vozy',
  'header.main.menu.item.alternative': 'Alternativní pohony',
  'header.main.menu.item.watchdog': 'Hlídací pes',
  'header.main.menu.item.models': 'Přehled modelů v nabídce',
  'header.main.menu.item.financing': 'Financování vozů',
  'header.main.menu.item.warranty': 'Prodloužená záruka',
  'header.main.menu.item.insurance': 'Škoda pojištění',
  'header.main.menu.item.faq': 'Nejčastější dotazy',
  'header.main.menu.item.gdpr': 'Zpracování osobních údajů',
  'header.main.menu.item.cookies': 'Informace o cookies',
  'header.main.menu.item.copyright': 'Copyright',
  'header.main.menu.item.sitemap': 'Mapa stránek',

  'footer.title.information': 'Informace',
  'footer.title.privacy': 'Ochrana osobních údajů',
  'footer.title.other': 'Ostatní',
  'footer.title.services': 'Služby',
  'footer.item.sitemap': 'Mapa stránek',
  'footer.item.makes': 'Značky v nabídce',
  'footer.item.cookies': 'Cookies',
  'footer.item.cookies.settings': 'Nastavení cookies',
  'footer.item.suggestions': 'Systém pro zpracování podnětů',
  'footer.item.contact': 'Kontakt',
  'footer.item.questions': 'Dotazy',
  'footer.item.career': 'Kariéra',
  'footer.item.financing': 'Chci financovat vůz',
  'footer.item.insurance': 'Chci pojistit vůz',
  'footer.item.insurance.link': '/insurance',
  'footer.bar.plus': 'Plus',
  'footer.claim.plus': 'Plus',
  'footer.claim.text': '©Škoda Auto a.s. {{YEAR}}',

  'livechat.text': 'Můžeme Vám poradit?',
  'cookies.text1':
    'Tato stránka používá cookies. Více informací o zpracování vašich osobních údajů na jejich základě a o vašich právech naleznete v',
  'cookies.link':
    'Informaci o zpracování osobních údajů prostřednictvím cookies a jiných webových technologií.',
  'cookies.text2':
    'Můžete udělit souhlas se zpracováním osobních údajů rovněž pro účely uložení preferencí uživatele napříč webovými stránkami, statistiku a analýzu chování uživatele, oslovování s nabídkou produktů a propojení se sociálními sítěmi.',
  'cookies.agree': 'Souhlasím',
  'cookies.disagree': 'Nesouhlasím',

  'compatibility.title':
    'Omlouváme se, ale Váš prohlížeč nepodporuje zobrazení těchto webových stránek.',
  'compatibility.text1':
    'Tato zpráva se Vám zobrazila, protože používáte verzi internetového prohlížeče Microsoft Internet Explorer, který stránka z bezpečnostních důvodů nepodporuje. Pro zvýšení bezpečnosti a správné zobrazení stránek doporučujeme použít jiný prohlížeč.',
  'compatibility.text2':
    'Podporované prohlížeče: Microsoft Edge, Google Chrome, Mozilla Firefox, Safari.',
  'compatibility.text3': 'Děkujeme za pochopení, Váš program Škoda Plus.',
  'compatibility.action': 'Aktualizovat prohlížeč',

  'basic.filter.tab1': 'Vím, jaký vůz chci',
  'basic.filter.tab2': 'Vím, k čemu vůz chci',
  'basic.filter.dropdown.model.label': 'Model',
  'basic.filter.dropdown.model.choose': 'Vyberte model',
  'basic.filter.dropdown.model.zero.loading': 'Načítání modelů',
  'basic.filter.dropdown.model.zero.unavailable': 'Nedostupný',
  'basic.filter.dropdown.make.label': 'Značka',
  'basic.filter.dropdown.fuelType.label': 'Typ paliva',
  'basic.filter.dropdown.fuelType.choose': 'Vyberte palivo',
  'basic.filter.dropdown.bodyType.label': 'Karoserie',
  'basic.filter.dropdown.bodyType.choose': 'Vyberte karoserii',
  'basic.filter.slider.price.label': 'Cena',
  'basic.filter.slider.price.max': 'Neomezeno',
  'basic.filter.slider.price.unit': 'Kč',
  'basic.filter.slider.mileage.label': 'Najeto',
  'basic.filter.slider.mileage.max': 'Neomezeno',
  'basic.filter.slider.mileage.unit': 'Km',
  'basic.filter.slider.firstRegistration.label': 'Do provozu',
  'basic.filter.submit.button.text': 'Zobrazit {{carsCount}} {{carsLabel}}',
  'basic.filter.submit.button.text.default': 'Načítání vozů',
  'basic.filter.button.redirect.label': 'Přejít na sesyp vozů s vybranými filtry',
  'basic.filter.button.advanced': 'Rozšířené vyhledávání',

  'purpose.filter.yes': 'Ano',
  'purpose.filter.no': 'Ne',
  'purpose.filter.submit.button': 'Zobrazit {{carsCount}} {{carsLabel}}',
  'purpose.filter.submit.button.default': 'Ukažte mi vozy',
  'purpose.filter.button.redirect': 'Přejít na přehled vozů',
  'purpose.filter.slider.price.label': 'Cena',
  'purpose.filter.slider.price.max': 'Neomezeno',
  'purpose.filter.slider.price.unit': 'Kč',
  'purpose.filter.dropdown.choose': 'Vyberte',
  'purpose.filter.dropdown.not.important': 'Nezáleží',
  'purpose.filter.from-4-to-5': '4 až 5',
  'purpose.filter.five-and-more': '5 a více',
  'purpose.filter.six-and-more': '6 a více',
  'purpose.filter.doesnt-matter': 'Nezáleží',
  'purpose.filter.two': '2',
  'purpose.filter.more-then-five': '6 a více',
  'purpose.filter.less-then-50k': 'Do 50 000 km',
  'purpose.filter.less-then-120k': 'Do 120 000 km',
  'purpose.filter.no-limit': 'Neomezeno',
  'purpose.filter.small': 'Nízký',
  'purpose.filter.medium': 'Střední',
  'purpose.filter.big': 'Vysoký',
  'purpose.filter.family': 'Rodina',
  'purpose.filter.traveling': 'Cestování',
  'purpose.filter.work': 'Práce',
  'purpose.filter.city': 'Do města',
  'purpose.filter.fun': 'Zábava',
  'purpose.filter.seats-count': 'Počet pasažérů',
  'purpose.filter.navigation-system': 'Navigace',
  'purpose.filter.all-wheel-drive': 'Pohon všech kol',
  'purpose.filter.isofix': 'ISOFIX',
  'purpose.filter.fuel': 'Typ paliva',
  'purpose.filter.mileage': 'Najeto',
  'purpose.filter.towing-bracket': 'Tažné zařízení',
  'purpose.filter.body-type': 'Karoserie',
  'purpose.filter.transmission': 'Převodovka',
  'purpose.filter.color': 'Barva',
  'purpose.filter.engine-power': 'Výkon',
  'purpose.filter.equipment': 'Výbava',

  'header.search.label': 'Hledat',
  'header.search.category.dealers': 'Dealeři',
  'header.search.category.pages': 'Stránky',
  'header.search.not.found': 'Nenalezeno...',

  'general.skodaPlus': 'Škoda Plus',
  'general.filter.no-limit': 'Neomezeno',
  'general.video.not.supported': "Sorry, your browser doesn't support embedded videos.",
  'general.currency': 'Kč',
  'general.noVAT': ' bez DPH',
  'general.monday': 'Pondělí',
  'general.tuesday': 'Úterý',
  'general.wednesday': 'Středa',
  'general.thursday': 'Čtvrtek',
  'general.friday': 'Pátek',
  'general.saturday': 'Sobota',
  'general.sunday': 'Neděle',
  'general.usedYearPlusCars': 'roční, ojeté a vyzkoušené vozy',
  'general.404': 'Zpet na list',

  'car.params.carID': 'ID',
  'car.params.type': 'Typ',
  'car.params.fuel': 'Typ paliva',
  'car.params.make': 'Značka',
  'car.params.model': 'Model',
  'car.params.fuelType': 'Typ paliva',
  'car.params.mileage': 'Najeto',
  'car.params.towing-bracket': 'Tažné zařízení',
  'car.params.body-type': 'Karoserie',
  'car.params.engineCapacity': 'Objem motoru',
  'car.params.transmission': 'Převodovka',
  'car.params.color': 'Barva',
  'car.params.paintType': 'Druh barvy',
  'car.params.engine-power': 'Výkon',
  'car.params.equipment': 'Výbava',
  'car.params.fromCountry': 'Původ',
  'car.params.originalPrice': 'Cena nového vozu',
  'car.params.firstSeller': 'První majitel',
  'car.params.innerEquipment': 'Vnitřní výbava',
  'car.params.outerEquipment': 'Vnější výbava',
  'car.params.safetyEquipment': 'Bezpečnost a technika',
  'car.params.dealer': 'Prodejce',
  'car.params.firstRegistration': 'Do provozu',
  'car.params.region': 'Region',
  'car.params.price': 'Cena',
  'car.params.bodyType': 'Karoserie',
  'car.params.condition': 'Stav vozu',
  'car.params.yes': 'Ano',
  'car.params.no': 'Ne',
  'car.params.audio': 'Audio',
  'car.params.engine': 'Pohon a technika',
  'car.params.crashed': 'Havarováno',
  'car.params.uncrashed': 'Nehavarováno',
  'car.params.certificateCebia': 'Certifikát Cebia',
  'car.params.other': 'Ostatní',
  'car.params.bodyType.seatsCount': 'Karoserie/počet míst:',
  'car.params.serviceBook': 'Servisní knížka:',
  'car.params.vin': 'VIN:',
  'car.params.demoCar': 'Předváděcí vůz:',
  'car.params.makeAndModel': 'Značka a model',
  'car.params.warrantyTill': 'Záruka do',
  'car.params.instalment': 'Měsíční splátka',
  'car.params.emission': `Emise CO<sub>2</sub>`,

  'color.label': 'Barva',
  'color.metallic.label': 'Metalický lak',
  'color.undefined.label': 'Undefined color',

  'bodyTypes.suv': 'SUV',
  'bodyTypes.sedan': 'sedan',
  'bodyTypes.hatchback': 'hatchback',
  'bodyTypes.combi': 'kombi',
  'bodyTypes.kabrio': 'Kabriolet',

  'car.props.mileage.label': 'Najeto:',
  'car.props.mileage.unit': 'Km',
  'car.props.mileage.firstRegistration': 'Do provozu:',

  'button.compare.vehicle': 'Porovnat vůz',
  'button.compare.vehicle.remove': 'Odebrat z porovnání',
  'button.compare.vehicle.detail': 'Porovnat {{carsCount}} {{carsLabel}}',
  'button.compare.vehicle.detail.remove': 'Zrušit',
  'compare.financing.price': 'Cena při financování',
  'compare.financing.price.unit.noVAT': ' bez DPH',
  'compare.financing.daily.line1': 'Orientační denní',
  'compare.financing.daily.line2': 'splátka včetně pojištění',
  'compare.financing.monthly.line1': 'Orientační měsíční',
  'compare.financing.monthly.line2': 'splátka včetně pojištění',
  'compare.financing.isPreview': 'Předváděcí vůz – dostupnost na dotaz',

  'cert.tooltip.vin': 'VIN',
  'cert.tooltip.showVin': 'Zobrazit VIN',
  'cert.tooltip.cebia.download': 'Stáhnout certifikát CEBIA',
  'carDetail.cebiaButton': 'Zkrácený výpis CEBIA',
  'cert.tooltip.skoda1.download': 'Stáhnout certifikát Škoda',
  'cert.tooltip.skoda2.download': 'Stáhnout certifikát Škoda',
  'cert.tooltip.certificate': 'Certifikát',

  'transmission.automatic': 'automatická',
  'transmission.manual': 'manuální',
  'paintType.pearl': 'perleť',
  'paintType.metallic': 'metalická',
  'paintType.basic': 'základní',

  'car.detail.back': 'Zpět na přehled vozů',
  'car.detail.certificate': 'Certifikát',
  'car.detail.condition': 'Stav vozu',
  'car.detail.summary': 'Souhrn',
  'car.detail.documents': 'Dokumenty',
  'car.detail.conditionFixed':
    'Automobil byl havarovaný a prošel kompletní výměnou všech poškozených dílů. Poté prošel rukama našich servisních techniků a auto je jak nové. Toto garantujeme a na automobil se vztahuje klasická záruka jako na auto nebourané.',
  'car.detail.disclaimer':
    'Údaje obsažené v této kartě vozu mají informativní charakter. Tato indikativní nabídka není nabídkou ve smyslu § 1731 nebo § 1732 občanského zákoníku, ani se nejedná o veřejný příslib dle § 1733 občanského zákoníku. Z této indikativní nabídky nevzniká nárok na uzavření smlouvy.',
  'car.detail.serviceInspection': 'STK do',
  'car.detail.technicalInspection': 'STK do',
  'car.detail.unspecified': 'Nespecifikováno',
  'car.detail.certificateCebia': 'Certifikát Cebia',
  'car.detail.carID': 'ID vozu',
  'car.detail.report': 'Nahlásit inzerát',
  'car.detail.askUs': 'Máte dotaz',
  'car.detail.button': '{{carsCount}} {{carsLabel}} v nabídce',
  'car.detail.tab.equipment': 'Výbava',
  'car.detail.tab.technicalParams': 'Technické parametry',
  'car.detail.tab.note': 'Poznámka',
  'car.detail.tab.warranties': 'Záruka',
  'car.detail.tab.financing': 'Financování',
  'car.detail.tab.insurance': 'Pojištění',
  'car.detail.note':
    '„Údaje obsažené v nabídce mají pouze informativní charakter. Tato indikativní nabídka není nabídkou ve smyslu § 1731 nebo § 1732 občanského zákoníku, ani se nejedná o veřejný příslib dle § 1733 občanského zákoníku. Z této indikativní nabídky nevzniká klientovi nárok na uzavření smlouvy.“',
  'car.detail.ask': 'Poptat vozidlo',

  'consent.gdpr.marketing.line1': 'Tímto ',
  'consent.gdpr.marketing.line2':
    ' za účelem Oslovování s nabídkou produktů, služeb a výzkumu trhu značky Škoda Auto.',
  'consent.gdpr.marketing.link': 'uděluji souhlas se zpracováním svých osobních údajů',
  'consent.marketing.content':
    'Tímto uděluji souhlas se zpracováním svých osobních údajů za účelem Oslovování s nabídkou produktů, služeb a výzkumu trhu značky Škoda Auto.',
  'consent.gdpr.text.line': 'Tímto ',
  'consent.gdpr.text.link':
    'uděluji souhlas se zpracováním svých osobních údajů za účelem Kontaktování kupujícího za účelem prodeje vybraného ojetého vozu.',

  'dealer.form.carId.label': 'ID vozu:',
  'dealer.form.imInterestedIn': 'Mám zájem o',
  'dealer.form.carID': 'ID vozu',
  'dealer.form.name': 'Jméno',
  'dealer.form.surname': 'Přijmení',
  'dealer.form.phone': 'Telefon',
  'dealer.form.mail': 'E-mail',
  'dealer.form.vehicleCondition': 'Stav vozidla',
  'dealer.form.financing': 'Financování',
  'dealer.form.meetingWithDealer': 'Schůzku s prodejcem',
  'dealer.form.somethingElse': 'Něco jiného',
  'dealer.form.callDealer': 'Zobrazit tel číslo',
  'dealer.form.sendDataToDealer': 'Odeslat zprávu prodejci',
  'dealer.form.message.error': 'Formulář se nepodařilo odeslat. Zkuste znovu',
  'dealer.form.message.type': 'Musíte vybrat alespoň jednu možnost: Mám zájem o',
  'dealer.form.message.sending': 'Odesílám...',
  'dealer.form.message.send': 'Odeslat formulář',
  'dealer.form.message.send.success': 'Formulář byl odeslán.',
  'dealer.form.message.invalid':
    'Formulář obsahuje chyby. Vyplňte, prosím, správně všechna povinná pole a zkuste znovu.',
  'dealer.form.call.label': 'Zavolat dealerovi',
  'dealer.form.show.phone.label': 'Zobrazit tel. číslo',

  'extra.features.previewType.request': 'Na dotaz',
  'extra.features.previewType.sale': 'Na prodej',
  'extra.features.isSkodaPlus.title': 'Vyzkoušený vůz',
  'extra.features.isOneYearCar.title': 'Roční vůz',
  'extra.features.isEconomical.title': 'Úsporný vůz',
  'extra.features.isPreview.title': 'Předváděcí vůz',
  'extra.features.isPreview.info': 'info o dostupnosti v poznámce',

  'dealer.detail.title': 'O prodejci',
  'dealer.detail.carCount': '{{carsCount}} {{carsLabel}} v nabídce',
  'dealer.detail.completeOffer': 'Objevte celou nabídku ŠkodaPlus',
  'dealer.detail.goTo': 'Přejít na',
  'dealer.detail.about': 'O prodejci',
  'dealer.detail.contact.title': 'Kontaktovat prodejce',
  'dealer.detail.contact.firstName': 'Jméno',
  'dealer.detail.contact.lastName': 'Příjmení',
  'dealer.detail.contact.phone': 'Telefon',
  'dealer.detail.contact.email': 'E-mail',
  'dealer.detail.contact.note': 'Váš komentář*',
  'dealer.detail.contact.error': 'Formulář se nepodařilo odeslat. Zkuste znovu',
  'dealer.detail.contact.invalid':
    'Formulář obsahuje chyby. Vyplňte, prosím, správně všechna povinná pole a zkuste znovu.',
  'dealer.detail.contact.send': 'Odeslat',
  'dealer.detail.contact.sending': 'Odesílám...',
  'dealer.detail.contact.send.success': 'Formulář byl odeslán.',
  'dealer.detail.contact.consent.line1':
    'Stisknutím tlačítka Odeslat potvrzuji, že jsem se seznámil se ',
  'dealer.detail.contact.consent.line2':
    'Zásadami zpracování mých osobních údajů za účelem Realizace prodeje vozu.',

  'recap.bubbles.range': 'až',
  'recap.bubbles.min': 'nejméně',
  'recap.bubbles.max': 'nejvíce',
  'recap.bubbles.price': 'Cena',
  'recap.bubbles.instalment': 'Splátka',
  'recap.bubbles.price.unit': 'Kč',
  'recap.bubbles.mileageRange': 'Najeto',
  'recap.bubbles.mileageRange.unit': 'Km',
  'recap.bubbles.seatsCountRange': 'Počet sedadel',
  'recap.bubbles.enginePower': 'Výkon motoru',
  'recap.bubbles.enginePower.unit': 'kW',
  'recap.bubbles.engineCapacity': 'Obsah motoru',
  'recap.bubbles.engineCapacity.unit': 'ccm3',
  'recap.bubbles.doorsCountRange': 'Počet dveří',
  'recap.bubbles.firstRegistration': 'Datum registrace',
  'recap.bubbles.removeAll': 'Vymazat vše',

  'small.filter.title': 'Řazení',
  'small.filter.firstOwner': 'První majitel',
  'small.filter.hasServiceBook': 'Servisní knížka',
  'small.filter.isSkodaPlus': 'Vyzkoušené vozy',
  'small.filter.isOneYearCar': 'Roční vozy',
  'small.filter.isEconomical': 'Úsporné vozy',
  'small.filter.isUsed': 'Ojeté vozy',
  'small.filter.isDemo': 'Předváděcí vozy',
  'small.filter.PRICE_ASC': 'Cena od nejlevnějších',
  'small.filter.PRICE_DESC': 'Cena od nejdražších',
  'small.filter.MILEAGE_ASC': 'Najeto od spoda',
  'small.filter.MILEAGE_DESC': 'Najeto od shora',
  'small.filter.FIRST_REGISTRATION_ASC': 'Rok přihlášení od spoda',
  'small.filter.FIRST_REGISTRATION_DESC': 'Rok přihlášení od shora',
  'small.filter.CREATED_ASC': 'Od nejstarších nabídek',
  'small.filter.CREATED_DESC': 'Od nejnovějších nabídek',

  'toolbar.carDetailName': 'Karta vozu',
  'toolbar.mySelection': 'Můj výběr',
  'toolbar.lastViewed': 'Naposledy prohlédnuté vozy',
  'toolbar.showResults': 'Zobrazit',
  'toolbar.loadingResults': 'Načítání vozů',
  'toolbar.loadingResults.threeDots': 'Načítání vozů...',
  'toolbar.mainFilterButton': '{{carsCount}} {{carsLabel}}',
  'toolbar.buttonAdvancedSearch': 'Rozšířené vyhledávání',
  'toolbar.compareButton': 'Porovnat {{carsCount}} {{carsLabel}}',

  'filter.close': 'Zavřít',
  'filter.title': 'Parametry vozů',
  'filter.show.line1': '{{carsLabel}}',
  'filter.show.line2': '{{carsLabel}}',
  'filter.show.loading': 'Načítám vozy',
  'filter.button.label': 'Filtr',

  'skofinOffers.offer2': 'Zimní kola zdarma',

  'selling.images.error': 'Musíte vložit alespoň 3 fotografie',
  'selling.images.size.error.part1': 'Některé soubory nedosahují min. povolenou velikost',
  'selling.images.size.error.part2': 'KB, a proto nebyly nahrány',
  'selling.images.fileType.error': 'Některé soubory nejsou povoleného typu, a proto nebyly nahrány',
  'selling.gdpr.consent.content':
    'Tímto uděluji souhlas se zpracováním svých osobních údajů za účelem Zprostředkování vytvoření nabídky vozidla k prodeji.',
  'selling.marketing.consent.content':
    'Tímto uděluji souhlas se zpracováním svých osobních údajů za účelem Oslovování s nabídkou produktů, služeb a výzkumu trhu značky Škoda Auto.',

  'selling.gdpr.marketing.line1': 'Tímto ',
  'selling.gdpr.marketing.line2':
    ' za účelem Oslovování s nabídkou produktů, služeb a výzkumu trhu značky Škoda Auto.',
  'selling.gdpr.marketing.link': 'uděluji souhlas se zpracováním svých osobních údajů',
  'selling.gdpr.text.line': 'Tímto ',
  'selling.gdpr.text.link':
    'uděluji souhlas se zpracováním svých osobních údajů za účelem Zprostředkování vytvoření nabídky vozidla k prodeji.',
  'selling.offer.question': 'Komu chcete vůz nabídnout?',
  'selling.offer.description':
    'Zvolte prosím město odkud jste a odkud nám vůz přivezete. Dále Vám určením vzdálenosti nabídneme obchodní partnery, kterým se nabídka vašeho vozu k výkupu odešle. V případě zájmu obchodního partnera, po předchozí dohodě, přistavíte váš vůz na pobočku Škoda Plus.',
  'selling.offer.city': 'Město',
  'selling.offer.distance': 'Vzdálenost',
  'selling.offer.input.distance': 'Zadat vzdálenost',
  'selling.offer.send': 'Odeslat poptávku',
  'selling.offer.sendForm': 'Odeslat formulář',
  'selling.offer.sending': 'Odesílám...',
  'selling.offer.send.success': 'Formulář byl odeslán.',
  'selling.offer.error.invalid':
    'Formulář obsahuje chyby. Vyplňte, prosím, správně všechna povinná pole a zkuste znovu.',
  'selling.offer.error.tryAgain': 'Formulář se nepodařilo odeslat. Zkuste znovu',
  'selling.offer.error.dealers':
    'Ve zvolené oblasti nebyl nalezen žádný obchodní partner Škoda Plus. Rozšiřte prosím vzdálenost hledání.',
  'selling.offer.closest.partners': 'Nejbližší obchodní partneři Škoda Plus:',
  'selling.form.title': 'Osobní údaje',
  'selling.form.firstName': 'Jméno',
  'selling.form.lastName': 'Příjmení',
  'selling.form.phone': 'Telefon',
  'selling.form.contact.preference.label': 'Preferuji kontakt',
  'selling.form.contact.preference.phone': 'Telefonicky',
  'selling.form.contact.preference.email': 'E-mailem',
  'selling.form.email': 'E-mail',
  'selling.form.carDetails.label': 'Údaje o vozidle',
  'selling.form.make': 'Značka*',
  'selling.form.model': 'Model',
  'selling.form.fuel': 'Palivo*',
  'selling.form.mileage': 'Najeto',
  'selling.form.firstRegistrationYear': 'Rok výroby*',
  'selling.form.vin': 'VIN kód',
  'selling.form.note': 'Stručný popis',
  'selling.form.serviceBook': 'Servisní knížka',
  'selling.form.crashed': 'Nehavarováno',
  'selling.form.firstOwner': 'První majitel',
  'selling.form.tradeIn': 'Mám zájem o protiúčet',
  'selling.form.loadImages': 'Nahrát fotografie + * (min. velikost {{count}} KB)',
  'selling.form.photo.hint': 'Jak mají fotografie vypadat?',

  'report.wrongPriceText': 'nesprávně uvedena cena',
  'report.carNotAvailableText': 'vozidlo není fyzicky přítomné na prodejní ploše u obchodníka',
  'report.wrongPhotoText': 'nesprávné fotografie',
  'report.duplicityText': 'duplicita',
  'report.selledText': 'prodáno',
  'report.otherText': 'jiné nejasnosti v ceně',
  'report.state.unsent': 'Nahlásit problém',
  'report.state.sent': 'Problém nahlášen',
  'report.state.error': 'Chyba, zkusit znovu',
  'report.title': 'Nahlásit vůz',
  'report.text':
    'Domníváte-li se, že je tento inzerát klamavý nebo zavádějící, můžete na to nyní upozornit náš tým pro zajištění kvality webu. Inzerát, proti kterému vznášíte námitku, prověříme a v případě průkazného porušení pravidel pro inzerování na našem webu učiníme potřebná opatření. Náš server si vyhrazuje právo na způsob řešení námitky. O přijatém opatření vás již nebudeme informovat.',
  'report.tooltip.wrongPrice':
    'správně: cena vozu prezentovaná na ŠkodaPlus je celková cena vozu s DPH platná při koupi za „hotové“ a cena bez DPH. Případná jiná cena, cena vozu na leasing, na splátky, financování v ceně atd. je vždy uváděna v odstavci „další“',
  'report.tooltip.carNotAvailable':
    'správně: vozy vystavené na ŠkodaPlus musí být fyzicky přítomny na prodejní ploše označené CI prvky obchodníka, u kterého jsou prezentovány a musí být označeny POS materiály',
  'report.tooltip.wrongPhoto':
    'správně: každý vůz musí být nafoceno zvlášť a nesmí být použity ilustrační fotografie',
  'report.tooltip.duplicity': 'jedno vozidlo je zveřejněno ve více inzerátech',
  'report.tooltip.sold':
    'vozidlo je inzerováno i přes to, že je již prodané, prodejce jej již nemá',
  'report.tooltip.other': 'popište níže v poznámce',
  'report.note': 'Poznámka k námitce',
  'report.close': 'Zavřít',

  'technical.parameters.unspecified': 'Nespecifikováno',
  'technical.parameters.evidenceNumber': 'Evidenční číslo',
  'technical.parameters.color': 'Barva',
  'technical.parameters.colorType': 'Typ barvy',
  'technical.parameters.firstOwner': 'První majitel',
  'technical.parameters.doorsCount': 'Počet dveří',
  'technical.parameters.seatsCount': 'Počet sedadel',
  'technical.parameters.consumption': 'Spotřeba',
  'technical.parameters.bodyType': 'Karoserie',
  'technical.parameters.origin': 'Původ',
  'technical.parameters.serviceInspection': 'STK do',
  'technical.parameters.serviceBook': 'Servisní knížka',

  'list.noResults': 'Vašemu výběru neodpovídá žádný záznam, vyhledejte prosím jinou kombinaci...',
  'list.more': 'Načíst více aut',
  'list.loading': 'Načítání',

  'watchdog.setup': 'Nastavit hlídacího psa',
  'watchdog.setup.notification': 'Nastavit upozornění',
  'watchdog.setup.carList.button':
    'Nemůžete najít svůj vůz? Nastavte si hlídacího psa a my vám jej najdeme.',
  'watchdog.count5': '5 oznámení',
  'watchdog.count10': '10 oznámení',
  'watchdog.count20': '20 oznámení',
  'watchdog.week': '7 dní',
  'watchdog.2weeks': '14 dní',
  'watchdog.month': 'měsíc',
  'watchdog.gdpr.line1': 'Tímto ',
  'watchdog.gdpr.line2': ' za účelem Kontaktování kupujícího s nabídkou ojetých vozů.',
  'watchdog.gdpr.link': 'uděluji souhlas se zpracováním svých osobních údajů',
  'watchdog.gdpr.consent.content':
    'Tímto uděluji souhlas se zpracováním svých osobních údajů za účelem Kontaktování kupujícího s nabídkou ojetých vozů.',
  'watchdog.params.title': 'Parametry vozu',
  'watchdog.params.description':
    'Zadejte kritéria požadovaného vozu, a pokud se vůz objeví v naší nabídce, budeme vás e-mailem ihned kontaktovat. Zadáte-li mobil, můžete být telefonicky kontaktován prodejcem, pokud si to budete přát. Toto má výhodu, že budete mít informaci o dostupném vozu ještě před tím, než se objeví v nabídce na webu. Poptávka bude na serveru evidována, dokud neuplyne její platnost nebo dokud vám nebyl zaslán příslušný počet oznámení. Můžete uvést zároveň obě položky.',
  'watchdog.make': 'Značka',
  'watchdog.model': 'Model',
  'watchdog.fuel': 'Palivo',
  'watchdog.bodyType': 'Karoserie',
  'watchdog.region': 'Region',
  'watchdog.price': 'Cena',
  'watchdog.mileage': 'Najeto',
  'watchdog.firstRegistration': 'Do provozu',
  'watchdog.more': 'Více parametrů',
  'watchdog.advanced': 'Další parametry',
  'watchdog.firstOwner': 'První majitel',
  'watchdog.serviceBook': 'Servisní knížka',
  'watchdog.contact.title': 'Kontaktní údaje a platnost objednávky',
  'watchdog.contact.email': 'E-mail',
  'watchdog.contact.phone': 'Telefon',
  'watchdog.contact.count': 'Počet oznámení',
  'watchdog.contact.validity': 'Platnost',
  'watchdog.contact.note': 'Poznámka pro prodejce',
  'watchdog.contact.info':
    'Na e-mail bude systém zasílat upozornění na vozy vyhovující zvoleným kritériím. Poptávka bude na serveru evidována, dokud neuplyne její platnost nebo dokud vám nebyl zaslán příslušný počet oznámení. Můžete uvést zároveň obě položky.',
  'watchdog.contact.error.one': 'Musíte vyplnit alespoň jeden parametr vozu a kontaktní e-mail',
  'watchdog.contact.error.validity': 'Musíte vyplnit položku Platnost nebo Počet oznámení.',
  'watchdog.contact.error.note':
    'Vaše zpráva pro prodejce by neměla být delší než 2048 znaků. Nyní má {{count}} znaků.',
  'watchdog.contact.send': 'Odeslat poptávku',
  'watchdog.contact.send.success': 'Formulář byl odeslán',
  'watchdog.contact.send.error': 'Chyba, zkusit znovu',
  'watchdog.contact.close': 'Zavřít',

  'sitemap.title': 'Mapa stránek',
  'sitemap.about': 'O Škoda Plus',
  'sitemap.branches': 'Naše pobočky',
  'sitemap.insurance': 'Škoda pojištění',
  'sitemap.warranty': 'Prodloužená záruka',
  'sitemap.faq': 'Nejčastější dotazy',
  'sitemap.contact': 'Kontakt',
  'sitemap.buying': 'Kupuji vůz',
  'sitemap.list': 'Vyhledávání vozů',
  'sitemap.models': 'Přehled modelů v nabídce',
  'sitemap.alternative': 'Alternativní pohony',
  'sitemap.watchdog': 'Hlídací pes',
  'sitemap.selling': 'Prodávám vůz',
  'sitemap.financing': 'Financování vozů',
  'sitemap.offers': 'Akční nabídky',
  'sitemap.financing.insurance': 'Doplňkové pojištění',
  'sitemap.vw': 'o Volkswagen Financial Services',

  'dealerMapBranchesList.carsInList': 'vozů v nabídce',
  'dealerMap.enterDealerName': 'Zadejte název prodejce nebo lokalitu',
  'dealerMap.enterDealerName.loading': 'Zadejte název prodejce nebo lokalitu...',
  'dealerMap.places': 'Místa',
  'dealerMap.dealersHeader': 'Dealeři',
  'dealerMap.searchButton': 'Vyhledat',

  '404.title': 'Jejda, tahle stránka neexistuje!',
  '404.page': 'Stránka nenalezena',

  'brands.loading': 'Načítání značek a modelů...',
  'brands.empty': 'Seznam značek a modelů je prázdný.',
  'brands.loading.part': 'Načítání',

  'comparison.carDetail': 'Karta vozu',
  'comparison.title': 'Porovnání vozů',
  'comparison.title.desktop': 'Porovnání vozů',
  'comparison.export': 'Export do PDF',
  'comparison.share': 'Sdílet',
  'comparison.noVehicles': `Žádná vozidla nebyla přidána do porovnání, přidat je můžete na <a href='/list'>seznamu vozů</a>`,
  'comparison.back': 'Zpět na můj seznam',

  'photoSamples.title': 'Fotografie k prodávanému vozu',
  'photoSamples.text':
    'Všechny fotografie musí být ve formátu JPG, minimální rozlišení 1024 × 768, do velikosti 5 MB. Minimální počet fotografií jsou tři, maximální počet fotografií je 50. Čím více dobrých fotografií nám poskytnete, tím větší bude zájem o váš vůz. Na ilustrativních fotografiích níže se můžete inspirovat tím, jak by mohlo nafocení vašeho vozu probíhat.',
  'photoSamples.step.1': 'Pohled na celé auto šikmo zepředu',
  'photoSamples.step.2': 'Pohled na celé auto šikmo zezadu',
  'photoSamples.step.3': 'Pohled z levého boku',
  'photoSamples.step.4': 'Pohled zepředu',
  'photoSamples.step.5': 'Detail interiéru',

  'car.comparison.title': 'Porovnání vozidel',
  'howToChoose.title': 'Podle čeho vybíráte vůz',
  'homepage.title': 'Úvodní stránka',

  'cebia.form.name': 'Jméno',
  'cebia.form.name.error': 'Pole musí být vyplněno',
  'cebia.form.surname': 'Příjmení',
  'cebia.form.surname.error': 'Pole musí být vyplněno',
  'cebia.form.email': 'E-mail',
  'cebia.form.email.error': 'Musí být vyplněna validní emailová adresa',
  'cebia.form.phone': 'Telefon',
  'cebia.form.phone.error': 'Musí být vyplněno validní telefonní číslo',
  'cebia.form.send': 'Získat',
  'cebia.form.succes': 'Děkujeme, certifikát dorazí na Vaši emailovou adresu.',
  'cebia.form.error': 'Při odesílání formuláře došlo k chybě.',
  'cebia.form.dialogTitle': 'Zkrácený výpis Cebia certifikát',
  'cebia.form.dialogText': 'K zaslání výpisu od Vás budeme potřebovat kontaktní údaje.',

  'technical.parameters.batteryCapacity': 'Kapacita baterie',
} as const
